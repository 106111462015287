'use client';

import Link from 'next/link';

import { HomeIcon } from '@heroicons/react/20/solid';
import { usePathname } from 'next/navigation';
import { Mapping } from '.';

type Path = {
  name: string;
  href: string;
  current: boolean;
};

type BreadcrumbsClientProps = {
  mapping: Mapping;
};

export default function BreadcrumbsClient({ mapping }: BreadcrumbsClientProps) {
  const paths = useBreadcrumbs(mapping);

  if (!paths?.length) {
    return (
      <nav className="flex" aria-label="Breadcrumb">
        <ol
          role="list"
          className="flex items-center space-x-0 sm:space-x-2 md:space-x-4"
        >
          <li>
            <div>
              <Link href="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <p
                className="ml-0 text-sm font-medium text-neutral-950 sm:ml-2 md:ml-4"
                aria-current={'page'}
              >
                Start
              </p>
            </div>
          </li>
        </ol>
      </nav>
    );
  }

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol
        role="list"
        className="flex items-center space-x-0 sm:space-x-2 md:space-x-4"
      >
        <li>
          <div>
            <Link href="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {paths.map((route) => (
          <li key={route.name.toString()}>
            <div className="flex items-center">
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              {route.current ? (
                <p
                  className="ml-0 text-xs font-semibold text-neutral-800 sm:ml-2 md:ml-4 text-balance"
                  aria-current={route.current ? 'page' : undefined}
                >
                  {route.name.toString()}
                </p>
              ) : (
                <Link
                  href={route.href}
                  className="ml-0 text-sm font-medium text-gray-500 hover:text-gray-700 sm:ml-2 md:ml-4"
                  aria-current={route.current ? 'page' : undefined}
                >
                  {route.name.toString()}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

function useBreadcrumbs(mapping: Mapping) {

  const pathname = usePathname();

  const paths =
    pathname === '/'
      ? []
      : pathname
          .split('/')
          .filter(Boolean)
          .map((segment, index, array) => {
            const href = '/' + array.slice(0, index + 1).join('/');
            return {
              name: mapping[href]?.title || segment,
              href,
              current: index === array.length - 1,
            };
          });

  return paths;
}
